import { useSelector } from 'react-redux';

import { Card, CardContent, CardDescription } from '../ui/card';

const LiveGameInfo = () => {
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const { eventId, gameId } = gameSession;
  return (
    <Card className="h-12">
      <CardContent className="px-2 py-1">
        <CardDescription>Game Id: {gameId}</CardDescription>
        <CardDescription>Event Id: {eventId}</CardDescription>
      </CardContent>
    </Card>
  );
};

export default LiveGameInfo;
