import { useEffect } from 'react';

import Header from '../../components/Header';
import SwiperUpcomingGames from '../../components/SwiperUpcomingGames';

const Home = () => {
  useEffect(() => {
    document.title = `Главная | My Flag Football`;
  }, []);

  return (
    <>
      <Header />
      <main className="ml-4 md:mr-4">
        <SwiperUpcomingGames />
      </main>
    </>
  );
};

export default Home;
