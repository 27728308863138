import { useEffect } from 'react';
import { Form, Navigate, redirect } from 'react-router-dom';

import AxiosApi from '../../api/axios-api';
import Header from '../../components/Header';
import { Button } from '../../components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import { isAuthorizated, userLogin } from '../../services/auth-service';

export const loginAction = async ({ request }) => {
  const data = await request.formData();
  const username = data.get('username');
  const password = data.get('password');

  if (!username) {
    return {
      error: 'You must provide a username to log in',
    };
  }

  if (!password) {
    return {
      error: 'You must provide a password to log in',
    };
  }

  try {
    await userLogin(AxiosApi, { password, username });
  } catch (error) {
    return {
      error: 'Invalid login attempt',
    };
  }

  return redirect('/tournaments');
};

const Login = () => {
  useEffect(() => {
    document.title = `Логин | My Flag Football`;
  }, []);

  return (
    <>
      <Header />
      {!isAuthorizated() ? (
        <main className="mx-4">
          <Card className="mx-auto max-w-sm">
            <CardHeader>
              <CardTitle className="text-2xl">Hey, hello</CardTitle>
              <CardDescription>Log in to your account</CardDescription>
            </CardHeader>
            <CardContent>
              <Form method="post">
                <div className="grid gap-4">
                  <div className="grid gap-2">
                    <Label htmlFor="email">Login</Label>
                    <Input
                      aria-label="Your Login"
                      autoComplete="nickname"
                      id="login"
                      name="username"
                      placeholder="Your login"
                      required
                      type="login"
                    />
                  </div>
                  <div className="grid gap-2">
                    <div className="flex items-center">
                      <Label htmlFor="password">Password</Label>
                    </div>
                    <Input
                      aria-label="Your Password"
                      autoComplete="current-password"
                      id="password"
                      name="password"
                      placeholder="Your password"
                      required
                      type="password"
                    />
                  </div>
                  <Button className="w-full" type="submit">
                    Login
                  </Button>
                </div>
              </Form>
            </CardContent>
          </Card>
        </main>
      ) : (
        <Navigate to="/tournaments" />
      )}
    </>
  );
};

export default Login;
