import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Tabs, TabsList, TabsTrigger } from '../ui/tabs';

const Tab = ({ items, paramName = 'gender_type' }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeItem, setActiveItem] = useState(searchParams.get(paramName) || items[0]);

  const handleClick = (value) => {
    setActiveItem(value);
    navigate(`?${paramName}=${value}`);
  };

  useEffect(() => {
    const currentParam = searchParams.get(paramName);
    if (currentParam && items.includes(currentParam)) {
      setActiveItem(currentParam);
    }
  }, [searchParams, paramName, items]);

  return (
    <Tabs className="my-4 w-full" defaultValue={activeItem}>
      <TabsList className={`grid w-full grid-cols-3`}>
        {items.map((item) => (
          <TabsTrigger key={item} onClick={() => handleClick(item)} value={item}>
            {item}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default Tab;
