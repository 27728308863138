import { useSelector } from 'react-redux';

import { CardTitle } from '../ui/card';
import { Separator } from '../ui/separator';

const ScorePanel = () => {
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const {
    awayTeamName = gameSession.awayTeam.name,
    awayTeamScore = gameSession.awayScore,
    homeTeamName = gameSession.homeTeam.name,
    homeTeamScore = gameSession.homeScore,
  } = gameSession;

  return (
    <>
      <CardTitle className="mt-6 flex items-center gap-2 text-lg">
        <span>{homeTeamName}</span>
        <span className="flex gap-1">
          <span className="team-score">{homeTeamScore}</span>
          <span>:</span>
          <span className="team-score">{awayTeamScore}</span>
        </span>
        <span>{awayTeamName}</span>
      </CardTitle>
      <Separator className="my-1" />
    </>
  );
};

export default ScorePanel;
