import { useSelector } from 'react-redux';

import { Separator } from '../ui/separator';

const GameProgress = () => {
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const { currentDown, half, targetDowns } = gameSession;

  return (
    <>
      <div className="mb-4 flex gap-4">
        <p>Ход игры:</p>
        <div>
          <p>Половина: {half}</p>
          <p>
            Попытки: {currentDown} / {targetDowns}
          </p>
        </div>
      </div>
      <Separator className="my-1 mb-6" />
    </>
  );
};

export default GameProgress;
