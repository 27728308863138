import { Link } from 'react-router-dom';

import { Card, CardContent, CardDescription, CardTitle } from '../ui/card';

const TournamentItem = ({ dateStart, name, tournamentId }) => {
  const date = new Date(dateStart);
  const formattedDate = `${date.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}`;

  return (
    <Card className="w-full shadow-md">
      <Link to={`${tournamentId}/games`}>
        <CardContent className="flex flex-wrap items-center justify-between gap-4 p-2">
          <CardTitle className="text-lg">{name}</CardTitle>
          <CardDescription>{formattedDate}</CardDescription>
          <CardDescription>
            г. Зеленоград, Стадион {`"`}Центральный{`"`}
          </CardDescription>
        </CardContent>
      </Link>
    </Card>
  );
};

export default TournamentItem;
