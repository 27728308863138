import { Pencil, UserCheck, UserMinus } from 'lucide-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { deletePlayer, updateInfoPlayer } from '../../store/gameSessionSlice';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../ui/sheet';

const PlayerInfo = ({ editButtonVisible, player }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const { gameId } = gameSession;

  const { firstName, id, jerseyNumber, lastName } = player;

  const [newFirstName, setNewFirstName] = useState(firstName);
  const [newLastName, setNewLastName] = useState(lastName);
  const [newJerseyNumber, setNewJerseyNumber] = useState(jerseyNumber);

  const handleUpdatePlayerInfo = () => {
    dispatch(
      updateInfoPlayer({
        gameId,
        player: {
          firstName: newFirstName,
          id,
          jerseyNumber: newJerseyNumber,
          lastName: newLastName,
        },
      }),
    );

    navigate(`/live-game/${gameId}`);
  };

  const handleDeletePlayer = (idGame, idPlayer) => {
    dispatch(deletePlayer({ idGame, idPlayer }));

    navigate(`/live-game/${gameId}`);
  };

  return (
    <div className="flex w-full items-center justify-between">
      <p className="text-lg">
        <span className="mr-4 inline-block w-[2ch] text-right">{jerseyNumber}</span>
        {firstName} {lastName}
      </p>
      {editButtonVisible && (
        <Sheet>
          <SheetTrigger asChild>
            <Button size="icon">
              <Pencil className="size-4" />
            </Button>
          </SheetTrigger>
          <SheetContent className="w-full">
            <SheetHeader>
              <SheetTitle>Изменить информацию о игроке</SheetTitle>
            </SheetHeader>
            <div className="mt-4 grid gap-4">
              <Input
                max={99}
                min={0}
                name={`player${id}JerseyNumber`}
                onChange={(e) => setNewJerseyNumber(e.target.value)}
                placeholder="#"
                type="number"
                value={newJerseyNumber}
              />
              <Input
                name={`player${id}FirstName`}
                onChange={(e) => setNewFirstName(e.target.value)}
                placeholder="Имя"
                type="text"
                value={newFirstName}
              />
              <Input
                name={`player${id}LastName`}
                onChange={(e) => setNewLastName(e.target.value)}
                placeholder="Фамилия"
                type="text"
                value={newLastName}
              />
              <SheetClose asChild>
                <Button onClick={handleUpdatePlayerInfo}>
                  <UserCheck className="mr-4 size-4" /> Сохранить изменения
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button onClick={() => handleDeletePlayer(gameId, id)}>
                  <UserMinus className="mr-4 size-4" /> Удалить игрока
                </Button>
              </SheetClose>
            </div>
          </SheetContent>
        </Sheet>
      )}
    </div>
  );
};

export default PlayerInfo;
