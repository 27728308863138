import { useState } from 'react';
import { useSelector } from 'react-redux';

import PlayerInfo from '../../../components/PlayerInfo';
import PlayerNew from '../../../components/PlayerNew';
import { Button } from '../../../components/ui/button';
import GameEvents from '../GameEvents';

const ConfirmPlayers = () => {
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const { awayTeam, gameId, homeTeam } = gameSession;

  const [selectedTeamId, setSelectedTeamId] = useState(homeTeam.id);
  const [homeTeamConfirmed, setHomeTeamConfirmed] = useState(false);
  const [awayTeamConfirmed, setAwayTeamConfirmed] = useState(false);
  const [editingPlayers, setEditingPlayers] = useState(false);
  const [hideList, setHideList] = useState(true);

  const teamName = selectedTeamId === homeTeam.id ? homeTeam.name : awayTeam.name;
  const teamPlayers = selectedTeamId === homeTeam.id ? homeTeam.players : awayTeam.players;

  const sortTeamPlayers = [...teamPlayers].sort((a, b) => a.jerseyNumber - b.jerseyNumber);

  const handleNoChangesInPlayersHomeTeam = () => {
    setEditingPlayers(false);
    setHomeTeamConfirmed(true);
    setSelectedTeamId(awayTeam.id);
  };

  const handleNoChangesInPlayersAwayTeam = () => {
    setAwayTeamConfirmed(true);
    setHideList(false);
  };

  return (
    <>
      {hideList && (
        <div>
          <h2>{teamName}</h2>
          <div className="my-4 flex flex-wrap gap-2">
            {sortTeamPlayers.map((player) => (
              <PlayerInfo
                editButtonVisible={editingPlayers}
                gameId={gameId}
                key={player.id}
                player={player}
              />
            ))}
          </div>
          <div className="flex flex-wrap gap-4">
            {editingPlayers && <PlayerNew gameId={gameId} teamId={selectedTeamId} />}
            <Button className="w-full" onClick={() => setEditingPlayers(true)} variant="outline">
              Редактировать состав
            </Button>
            <Button
              className="w-full"
              onClick={
                homeTeamConfirmed
                  ? handleNoChangesInPlayersAwayTeam
                  : handleNoChangesInPlayersHomeTeam
              }
            >
              Продолжить
            </Button>
          </div>
        </div>
      )}
      {homeTeamConfirmed && awayTeamConfirmed && <GameEvents />}
    </>
  );
};

export default ConfirmPlayers;
