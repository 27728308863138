const setToken = (token, expires) => {
  localStorage.setItem('at', token);
  localStorage.setItem('expires', expires);
};

const getToken = () => localStorage.getItem('at');

const destroyToken = () => {
  localStorage.removeItem('at');
  localStorage.removeItem('expires');
};

const userLogout = (conn) => {
  destroyToken();
  conn.post('/auth/logout/').catch(() => {});
};

const userLogin = (conn, userCredentials) =>
  new Promise((resolve, reject) => {
    conn
      .post(
        '/auth/login/',
        {
          password: userCredentials.password,
          username: userCredentials.username,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: true,
        },
      )
      .then((response) => {
        const { accessToken, expires } = response.data;
        setToken(accessToken, expires);
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });

const refreshToken = async (conn) => {
  const headers = { Authorization: `Bearer ${getToken()}` };

  return conn
    .get('/auth/refresh/', { headers, withCredentials: true })
    .then((response) => {
      const { accessToken, expires } = response.data;
      setToken(accessToken, expires);
      return true;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        destroyToken();
      }
      return false;
    });
};

const isAuthorizated = () => {
  if (localStorage.getItem('at') && localStorage.getItem('expires')) {
    let utc = new Date();
    const offset = utc.getTimezoneOffset();
    utc = new Date(utc.getTime() + offset * 6000000);
    try {
      // eslint-disable-next-line no-new
      new Date(localStorage.getItem('expires'));
    } catch (error) {
      return '';
    }
    if (new Date(localStorage.getItem('expires')) > utc) {
      return true;
    }
    return refreshToken().then((result) => result);
  }
  return false;
};

export { getToken, isAuthorizated, refreshToken, userLogin, userLogout };
