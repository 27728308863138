import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Label } from '../../../components/ui/label';
import { RadioGroup, RadioGroupItem } from '../../../components/ui/radio-group';
import GameEvents from '../GameEvents';

const SelectAttackTeam = () => {
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const [disabledButton, setDisabledButton] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  const {
    awayTeamId = gameSession.awayTeam.id,
    awayTeamName = gameSession.awayTeam.name,
    homeTeamId = gameSession.homeTeam.id,
    homeTeamName = gameSession.homeTeam.name,
  } = gameSession;

  const handleRadioChange = (e) => {
    setDisabledButton(true);
    setSelectedTeamId(e.target.value);
  };

  return (
    <>
      <RadioGroup className="my-4 flex">
        <div className="flex items-center space-x-2">
          <RadioGroupItem id={homeTeamId} onClick={handleRadioChange} value={homeTeamId} />
          <Label className="text-2xl" htmlFor={homeTeamId}>
            {homeTeamName}
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem id={awayTeamId} onClick={handleRadioChange} value={awayTeamId} />
          <Label className="text-2xl" htmlFor={awayTeamId}>
            {awayTeamName}
          </Label>
        </div>
      </RadioGroup>
      <GameEvents disabled={!disabledButton} selectedTeamId={selectedTeamId} />
    </>
  );
};

export default SelectAttackTeam;
