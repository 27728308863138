import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../components/ui/button';
import { CardDescription } from '../../../components/ui/card';
import { flagSubmit } from '../../../store/gameSessionSlice';

const FlagScreen = ({ onFlagSelect }) => {
  const dispatch = useDispatch();
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const { awayTeam, currentDown, gameId, homeTeam, targetDowns } = gameSession;
  const [payload, setPayload] = useState({
    attackTeamId: null,
    currentDown: null,
    eventType: 'flag',
    gameId,
    playerId: null,
    targetDowns,
    teamId: null,
  });

  const [activeTeamAll, setActiveTeamAll] = useState(null);
  const [commandPick, setCommandPick] = useState(true);
  const [playerPick, setPlayerPick] = useState(false);
  const [selectAttackingTeam, setSelectAttackingTeam] = useState(false);
  const [downSelection, setDownSelection] = useState(false);
  const [targetSelection, setTargetSelection] = useState(false);
  const [selectTargetExecuted, setSelectTargetExecuted] = useState(false);

  const handleSelectTeam = (teamId) => {
    const activeTeam = teamId === homeTeam.id ? homeTeam : awayTeam;
    setPayload((prevPayload) => ({ ...prevPayload, teamId }));
    setActiveTeamAll(activeTeam);
    setCommandPick(false);
    setPlayerPick(true);
  };

  const handlePlayer = (playerId) => {
    setPayload((prevPayload) => ({ ...prevPayload, playerId }));
    setPlayerPick(false);
    setSelectAttackingTeam(true);
  };

  const handleAttackTeam = (attackTeamId) => {
    setPayload((prevPayload) => ({ ...prevPayload, attackTeamId }));
    setSelectAttackingTeam(false);
    setDownSelection(true);
  };

  const handleDown = (newCurrentDown) => {
    setPayload((prevPayload) => ({ ...prevPayload, currentDown: newCurrentDown }));
    setDownSelection(false);
    if (newCurrentDown === 1 && targetDowns === 'center') {
      setTargetSelection(true);
    } else {
      setSelectTargetExecuted(true);
    }
  };

  const handleSelectTarget = (newTargetDowns) => {
    setPayload((prevPayload) => ({ ...prevPayload, targetDowns: newTargetDowns }));
    setTargetSelection(false);
    setSelectTargetExecuted(true);
  };

  useEffect(() => {
    if (selectTargetExecuted) {
      dispatch(flagSubmit(payload));
      setSelectTargetExecuted(false);
      onFlagSelect();
    }
  }, [payload, selectTargetExecuted]);

  return (
    <>
      {commandPick && (
        <>
          <CardDescription>Какая команда нарушила правила?</CardDescription>
          <div className="mt-6 flex flex-wrap gap-4">
            <Button className="w-full" onClick={() => handleSelectTeam(homeTeam.id)}>
              {homeTeam.name}
            </Button>
            <Button className="w-full" onClick={() => handleSelectTeam(awayTeam.id)}>
              {awayTeam.name}
            </Button>
          </div>
        </>
      )}
      {playerPick && (
        <>
          <CardDescription>
            Какой игрок команды {activeTeamAll.name} нарушил правила?
          </CardDescription>
          <div className="mt-6 grid grid-cols-2 gap-4">
            {activeTeamAll.players.map((player) => (
              <Button key={player.id} onClick={() => handlePlayer(player.id)} variant="secondary">
                <span className="mr-2">{player.jerseyNumber}</span>
                {player.firstName} {player.lastName}
              </Button>
            ))}
            <Button onClick={() => handlePlayer(null)} variant="secondary">
              Без Игрока
            </Button>
          </div>
        </>
      )}
      {selectAttackingTeam && (
        <>
          <CardDescription>У какой команды будет владение?</CardDescription>
          <div className="mt-6 flex flex-wrap gap-4">
            <Button className="w-full" onClick={() => handleAttackTeam(homeTeam.id)}>
              {homeTeam.name}
            </Button>
            <Button className="w-full" onClick={() => handleAttackTeam(awayTeam.id)}>
              {awayTeam.name}
            </Button>
          </div>
        </>
      )}
      {downSelection && (
        <>
          <CardDescription>Какой будет следующая попытка?</CardDescription>
          <div className="mt-6 grid grid-cols-4 gap-4">
            {[1, 2, 3, 4].map((down) => (
              <Button
                key={down}
                onClick={() => handleDown(down)}
                variant={currentDown === down ? 'outline' : ''}
              >
                {down}
              </Button>
            ))}
          </div>
        </>
      )}
      {targetSelection && (
        <>
          <CardDescription>Команда начнет со своей половины?</CardDescription>
          <div className="mt-6 flex flex-wrap gap-4">
            <Button className="w-full" onClick={() => handleSelectTarget('center')}>
              Да
            </Button>
            <Button className="w-full" onClick={() => handleSelectTarget('goal')}>
              Нет
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default FlagScreen;
