import axios from 'axios';

import { getToken, refreshToken } from '../services/auth-service';
import camelToSnake from '../services/camelToSnake';
import snakeToCamel from '../services/snakeToCamel';

const hostName = process.env.REACT_APP_BACKEND_URL || window.location.origin;

const baseUrl = `${hostName}${process.env.REACT_APP_BASE_URL}`;

const AxiosApi = axios.create({
  baseURL: baseUrl,
  timeout: 1000,
});

AxiosApi.interceptors.request.use(async (config) => {
  if (config.data) {
    // eslint-disable-next-line no-param-reassign
    config.data = camelToSnake(config.data);
  }

  if (config.url === '/auth/login/' || config.url === '/auth/refresh/') {
    return config;
  }

  // const authStatus = isAuthorizated();
  // if (!authStatus) {
  //   const controller = new AbortController(AxiosApi);
  //   controller.abort();
  //   window.location = '/login/';
  // }
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

AxiosApi.interceptors.response.use(
  (response) => {
    response.data = snakeToCamel(response.data);
    return response;
  },
  async (error) => {
    if (error) {
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        error.response.data.detail === 'access_token_expired' &&
        // eslint-disable-next-line no-underscore-dangle
        !originalRequest._retry
      ) {
        // eslint-disable-next-line no-underscore-dangle
        originalRequest._retry = true;
        const tokenRefreshed = await refreshToken(AxiosApi);
        if (tokenRefreshed) {
          return AxiosApi(originalRequest);
        }
      }
      if (error.response.status === 401 && error.config.url === '/login/') {
        window.location = '/login/';
      }
    }
    return Promise.reject(error);
  },
);

export default AxiosApi;
