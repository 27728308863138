import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

import { updateGameSession } from '../../store/gameSessionSlice';
import { Button } from '../ui/button';
import { Card, CardContent, CardTitle } from '../ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form';
import { Input } from '../ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { SheetClose, SheetContent, SheetFooter, SheetHeader, SheetTitle } from '../ui/sheet';
import { toast } from '../ui/use-toast';

const GameChanger = ({ isSheetOpen }) => {
  const dispatch = useDispatch();
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const {
    attackTeamId,
    awayScore,
    awayTeam,
    currentDown,
    gameId,
    half,
    homeScore,
    homeTeam,
    targetDowns,
  } = gameSession;
  const [startValue, setStartValue] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);
  const formSchema = z.object({
    attackTeamName: z.enum([homeTeam.name, awayTeam.name], {
      required_error: 'Пожалуйста, выберите команду',
    }),
    awayScore: z.coerce
      .number({
        required_error: 'Пожалуйста, введите количество очков',
      })
      .min(0, { message: 'Количество очков не может быть отрицательным' }),
    currentDown: z.enum(['1', '2', '3', '4'], {
      required_error: 'Пожалуйста, выберите даун',
    }),
    half: z.enum(['1', '2'], {
      required_error: 'Пожалуйста, выберите половину игры',
    }),
    homeScore: z.coerce
      .number({
        required_error: 'Пожалуйста, введите количество очков',
      })
      .min(0, { message: 'Количество очков не может быть отрицательным' }),
    targetDowns: z.enum(['center', 'goal'], {
      required_error: 'Пожалуйста, выберите таргет даун',
    }),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const formValues = form.watch();

  useEffect(() => {
    if (isSheetOpen) {
      form.reset({
        attackTeamName: attackTeamId === homeTeam.id ? homeTeam.name : awayTeam.name,
        awayScore,
        currentDown: currentDown.toString(),
        half: half.toString(),
        homeScore,
        targetDowns,
      });
      setStartValue({
        attackTeamName: attackTeamId === homeTeam.id ? homeTeam.name : awayTeam.name,
        awayScore: awayScore.toString(),
        currentDown: currentDown.toString(),
        half: half.toString(),
        homeScore: homeScore.toString(),
        targetDowns,
      });
    }
  }, [isSheetOpen]);

  useEffect(() => {
    Object.entries(formValues).forEach(([key, value]) => {
      formValues[key] = value.toString();
    });

    if (JSON.stringify(formValues) === JSON.stringify(startValue)) {
      setIsFormChanged(false);
    } else {
      setIsFormChanged(true);
    }
  }, [formValues]);

  function onSubmit(data) {
    const updatedData = {
      ...data,
      attackTeamId: homeTeam.name === data.attackTeamName ? homeTeam.id : awayTeam.id,
      awayScore: parseInt(data.awayScore, 10) || 0,
      currentDown: parseInt(data.currentDown, 10) || 0,
      gameId,
      half: parseInt(data.half, 10) || 0,
      homeScore: parseInt(data.homeScore, 10) || 0,
    };
    delete updatedData.attackTeamName;

    Object.entries(formValues).forEach(([key, value]) => {
      formValues[key] = value.toString();
    });

    if (JSON.stringify(formValues) === JSON.stringify(startValue)) {
      toast({
        // TODO: проверить работу toast
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">{JSON.stringify(updatedData, null, 2)}</code>
          </pre>
        ),
        title: 'You submitted the following values:',
      });
    } else {
      dispatch(updateGameSession(updatedData));
    }
  }

  return (
    <>
      <SheetContent className="w-full overflow-y-auto">
        <SheetHeader>
          <SheetTitle>Уточнить данные игры</SheetTitle>
        </SheetHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Card className="mt-4">
              <CardContent className="p-2">
                <CardTitle className="text-base">Изменить счет</CardTitle>
                <FormField
                  control={form.control}
                  name="homeScore"
                  render={({ field }) => (
                    <FormItem className="flex items-center justify-between">
                      <FormLabel className="grow text-base">{homeTeam.name}</FormLabel>
                      {attackTeamId === homeTeam.id ? `🏈 ` : ''}
                      <div className="ml-2 flex flex-col items-end">
                        <FormControl>
                          <Input
                            className="mt-0 w-[8ch] text-base"
                            min={0}
                            placeholder={homeScore}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="awayScore"
                  render={({ field }) => (
                    <FormItem className="flex items-center justify-between">
                      <FormLabel className="grow text-base">{awayTeam.name}</FormLabel>
                      {attackTeamId === awayTeam.id ? `🏈 ` : ''}
                      <div className="ml-2 flex flex-col items-end">
                        <FormControl>
                          <Input
                            className="mt-0 w-[8ch] text-base"
                            min={0}
                            placeholder={awayScore}
                            type="number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card className="mt-4">
              <CardContent className="p-2">
                <FormField
                  control={form.control}
                  name="attackTeamName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-base">Изменить атакующую команду</FormLabel>
                      <Select defaultValue={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={homeTeam.name}>{homeTeam.name}</SelectItem>
                          <SelectItem value={awayTeam.name}>{awayTeam.name}</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card className="mt-4">
              <CardContent className="p-2">
                <FormField
                  control={form.control}
                  name="currentDown"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-base">Изменить даун</FormLabel>
                      <Select defaultValue={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {['1', '2', '3', '4'].map((down) => (
                            <SelectItem key={down} value={down}>
                              {down}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card className="mt-4">
              <CardContent className="p-2">
                <FormField
                  control={form.control}
                  name="targetDowns"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-base">Изменить таргет даун</FormLabel>
                      <Select defaultValue={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="center">center</SelectItem>
                          <SelectItem value="goal">goal</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card className="mt-4">
              <CardContent className="p-2">
                <FormField
                  control={form.control}
                  name="half"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-base">Изменить половину игры</FormLabel>
                      <Select defaultValue={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={field.value} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {['1', '2'].map((down) => (
                            <SelectItem key={down} value={down}>
                              {down}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <SheetFooter className="my-4 grid gap-2">
              <SheetClose asChild>
                <Button className="w-full" variant="outline">
                  Отменить
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button className="w-full" disabled={!isFormChanged} type="submit">
                  Изменить
                </Button>
              </SheetClose>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </>
  );
};

export default GameChanger;
