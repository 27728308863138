import GameEvents from '../GameEvents';

const TimeStarts = () => (
  <>
    <p>Всё готово.</p>
    <GameEvents />
  </>
);

export default TimeStarts;
