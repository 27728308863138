import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../components/ui/button';
import { updateEvent } from '../../../store/gameSessionSlice';
import SelectPlayers from '../SelectPlayers';

function AdditionalInfoScreen({ availableScreensValue, eventTypeValue, onAdditionalInfoSelect }) {
  const availableScreens = {
    additionalInfo: 'additionalInfo',
    playerSelection: 'playerSelection',
  };
  const dispatch = useDispatch();
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const { eventId, gameId } = gameSession;
  const { additionalInfo, needPlayerIdFromTeam } = availableScreensValue;
  const { actions } = additionalInfo;
  const [currentScreen, setCurrentScreen] = useState(false);
  const [selectTargetExecuted, setSelectTargetExecuted] = useState(false);
  const [payload, setPayload] = useState({ eventId, gameId });
  const [selectedPlayer, setSelectedPlayer] = useState(false);
  const [handleAction, setHandleAction] = useState({});

  const handleAdditionalInfo = (action) => {
    setHandleAction(action);
    setPayload((prevPayload) => ({ ...prevPayload, eventType: action.eventType }));
    if (action.needPlayerIdFromTeam === null) {
      setSelectTargetExecuted(true);
    } else {
      setCurrentScreen(availableScreens.playerSelection);
    }
  };

  const handlePlayerSelect = (playerId) => {
    if (handleAction.isAdditionalInfo) {
      setPayload((prevPayload) => ({
        ...prevPayload,
        additionalInfo: { ...prevPayload.additionalInfo, playerId },
      }));
      setSelectTargetExecuted(true);
    } else if (!selectedPlayer) {
      setSelectedPlayer(true);
      setPayload((prevPayload) => ({ ...prevPayload, playerId }));
      setCurrentScreen(availableScreens.additionalInfo);
    }
  };

  useEffect(() => {
    if (selectTargetExecuted) {
      dispatch(updateEvent(payload));
      setSelectTargetExecuted(false);
      onAdditionalInfoSelect();
    }
  }, [selectTargetExecuted]);

  useEffect(() => {
    if (needPlayerIdFromTeam !== null) {
      setCurrentScreen(availableScreens.playerSelection);
    } else {
      setCurrentScreen(availableScreens.additionalInfo);
    }
  }, []);

  return (
    <>
      {currentScreen === availableScreens.playerSelection && (
        <SelectPlayers eventTypeValue={eventTypeValue} onPlayerSelect={handlePlayerSelect} />
      )}
      {currentScreen === availableScreens.additionalInfo && (
        <>
          <h3>{additionalInfo.label}</h3>
          <div className="my-4 flex flex-wrap gap-4">
            {actions.map((action, index) => (
              <Button className="w-full" key={index} onClick={() => handleAdditionalInfo(action)}>
                {action.label}
              </Button>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default AdditionalInfoScreen;
