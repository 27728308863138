import { UserPlus } from 'lucide-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { addNewPlayer } from '../../store/gameSessionSlice';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../ui/sheet';

const PlayerNew = ({ teamId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const { gameId } = gameSession;

  const [newPlayer, setNewPlayer] = useState({ firstName: '', jerseyNumber: '', lastName: '' });
  const [buttonToCreateNewPlayer, setButtonToCreateNewPlayer] = useState(false);

  const addPlayer = () => {
    setNewPlayer({ firstName: '', jerseyNumber: '', lastName: '' });
    const player = { ...newPlayer };
    dispatch(addNewPlayer({ gameId, player, teamId }));
    setButtonToCreateNewPlayer(false);

    navigate(`/live-game/${gameId}`);
  };

  const handlePlayerChange = (e) => {
    const { name, value } = e.target;
    let checkValue = value;

    // eslint-disable-next-line no-restricted-globals
    if (name === 'jerseyNumber' && !isNaN(value)) {
      checkValue = parseInt(value, 10);
    }

    setNewPlayer({ ...newPlayer, [name]: checkValue });

    const { firstName, jerseyNumber, lastName } = { ...newPlayer, [name]: checkValue };
    setButtonToCreateNewPlayer(Boolean(firstName && jerseyNumber && lastName));
  };

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button>
          <UserPlus className="mr-2 size-4" /> Добавить ещё игрока
        </Button>
      </SheetTrigger>
      <SheetContent className="w-full">
        <SheetHeader>
          <SheetTitle>Добавить игрока?</SheetTitle>
        </SheetHeader>
        <div className="mt-4 grid gap-4">
          <Input
            autoFocus
            max={99}
            min={0}
            name="jerseyNumber"
            onChange={handlePlayerChange}
            placeholder="#"
            required
            type="number"
            value={newPlayer.jerseyNumber}
          />
          <Input
            className="player__name-input"
            name="firstName"
            onChange={handlePlayerChange}
            placeholder="Имя"
            required
            type="text"
            value={newPlayer.firstName}
          />
          <Input
            className="player__name-input"
            name="lastName"
            onChange={handlePlayerChange}
            placeholder="Фамилия"
            required
            type="text"
            value={newPlayer.lastName}
          />
          <SheetClose asChild>
            <Button disabled={!buttonToCreateNewPlayer} onClick={addPlayer}>
              <UserPlus className="mr-2 size-4" /> Добавить
            </Button>
          </SheetClose>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default PlayerNew;
