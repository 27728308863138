import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import ButtonBack from '../../components/ButtonBack';
import ScorePanel from '../../components/ScorePanel';
import Spinner from '../../components/Spinner';
import { Card, CardContent } from '../../components/ui/card';
import { clearGameSession, fetchGameSession } from '../../store/gameSessionSlice';

const GameBox = () => {
  const { gameId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(fetchGameSession(gameId));
      setLoading(false);
    };
    fetchData();

    return () => {
      dispatch(clearGameSession());
    };
  }, [gameId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <main className="mx-4">
        <ButtonBack />
        <Card>
          <CardContent>
            <ScorePanel />
          </CardContent>
        </Card>
      </main>
    </>
  );
};

export default GameBox;
