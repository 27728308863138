import { useSelector } from 'react-redux';

import { Button } from '../../../components/ui/button';
import { CardDescription } from '../../../components/ui/card';

const SelectPlayers = ({ activeTeamValue, eventTypeValue, onPlayerSelect }) => {
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const { attackTeamId, awayTeam, homeTeam } = gameSession;

  const attackTeam = attackTeamId === homeTeam.id ? homeTeam : awayTeam;
  const defenseTeam = attackTeamId !== homeTeam.id ? homeTeam : awayTeam;
  const activeTeam = activeTeamValue === 'attack' ? attackTeam : defenseTeam;

  const { lastQbId, name, players } = activeTeam;

  const questionEvent = {
    bad_snap: 'Кто сделал плохой снеп',
    conv_interception: 'Кто перехватил мяч',
    conv_pass_complete: 'Кто поймал реализацию',
    conv_pass_forward: 'Кто сделал пас',
    flag_pull: 'Кто сорвал флаг',
    interception_flag_pull: 'Кто сорвал флаг с игрока, который перехватил мяч',
    pass_complete: 'Кто поймал мяч',
    pass_forward: 'Кто сделал пас',
    run: 'Кто выносит мяч',
    sack: 'Кто сорвал флаг с квотербека',
    safety: 'Кто сорвал флаг, в зачетной зоне',
  };

  return (
    <>
      <h3>Команда: {name}</h3>
      <CardDescription>{questionEvent[eventTypeValue]}</CardDescription>
      <div className="my-6 grid grid-cols-2 gap-4">
        {players.map((player) => (
          <Button
            className="gap-2"
            key={player.id}
            onClick={() => onPlayerSelect(player.id)}
            variant={lastQbId === player.id ? 'outline' : 'secondary'}
          >
            <span>{player.jerseyNumber}</span>
            <span>
              {player.firstName} {player.lastName.slice(0, 1).toUpperCase()}
            </span>
          </Button>
        ))}
      </div>
    </>
  );
};

export default SelectPlayers;
