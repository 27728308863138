const Spinner = () => (
  <div className="spinner spinner_layout">
    <div className="spinner_wrapper">
      <svg
        fill="none"
        height="35"
        viewBox="0 0 35 35"
        width="35"
        xmlns="http://www.w3.org/2000/svg"
      >
        <>
          <path
            clipRule="evenodd"
            d="M33.4945 18.722C34.2477 10.0274 27.9053 2.36791 19.2721 1.45095C19.2405 1.44582 19.2085 1.44186 19.176 1.43911C18.4879 1.38078 17.8817 1.89147 17.822 2.57976C17.7624 3.26805 18.272 3.87331 18.9601 3.93164C26.2657 4.68702 31.6391 11.1605 31.0024 18.5108C30.3656 25.8611 23.9572 31.336 16.6284 30.8487C15.94 30.7926 15.3338 31.3033 15.2742 31.9916C15.2145 32.6799 15.7241 33.2851 16.4123 33.3435C16.4447 33.3462 16.4769 33.3477 16.5089 33.3479C25.174 33.8972 32.7413 27.4167 33.4945 18.722Z"
            fill="url(#paint0_linear_229_1090)"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M1.61561 15.98C2.3688 7.28536 9.9361 0.804862 18.6012 1.35409C18.6332 1.35435 18.6654 1.35584 18.6978 1.35858C19.386 1.41691 19.8955 2.02217 19.8359 2.71046C19.7763 3.39876 19.1701 3.90944 18.4819 3.85111C11.1529 3.36607 4.74445 8.84094 4.10772 16.1912C3.47098 23.5415 8.84433 30.015 16.1501 30.7681C16.8381 30.8287 17.3477 31.434 17.288 32.1223C17.2284 32.8106 16.6222 33.3213 15.934 33.2629C15.9016 33.2602 15.8696 33.2562 15.838 33.2511C7.20481 32.3341 0.862412 24.6747 1.61561 15.98Z"
            fill="#DE4C4D"
            fillRule="evenodd"
          />
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id="paint0_linear_229_1090"
              x1="22.4394"
              x2="30.9422"
              y1="4.58413"
              y2="21.3909"
            >
              <stop stopColor="#DE4C4D" />
              <stop offset="0.9999" stopColor="#DE4C4D" stopOpacity="0.01" />
              <stop offset="1" stopColor="#6859F5" stopOpacity="0" />
            </linearGradient>
          </defs>
        </>
      </svg>
    </div>
  </div>
);

export default Spinner;
