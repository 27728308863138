import { useSelector } from 'react-redux';

import ConfirmPlayers from './ConfirmPlayers';
import GameEvents from './GameEvents';
import SelectAttackTeam from './SelectAttackTeam';
import TimeStarts from './TimeStarts';

const LiveGame = () => {
  const { eventType } = useSelector((state) => state.gameSession.gameSession.flowInfo[0]);

  switch (eventType) {
    case 'confirm_roster':
      return <ConfirmPlayers />;
    case 'set_attacking_team':
      return <SelectAttackTeam />;
    case 'time_starts':
      return <TimeStarts />;
    default:
      return <GameEvents />;
  }
};

export default LiveGame;
