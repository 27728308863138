import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Card, CardDescription, CardHeader, CardTitle } from '../ui/card';

const tournaments = [
  {
    date: '21.04.2024 г.',
    id: 1,
    location: 'Москва, Лужники арена',
    name: 'Ruby Bowl',
  },
  {
    date: '28.04.2024 г.',
    id: 2,
    location: 'Москва, Лужники арена',
    name: 'Ruby Bowl 3',
  },
  {
    date: '29.09.2024 г.',
    id: 3,
    location: 'Москва, Сапсан арена',
    name: 'Memory Bowl',
  },
];

function SwiperUpcomingGames() {
  return (
    <>
      <h4 className="mb-4 scroll-m-20 text-xl font-semibold tracking-tight">Ближайшие турниры</h4>
      <Swiper
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        className="mySwiper"
        loop={true}
        modules={[Pagination]}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        slidesPerView={'auto'}
        spaceBetween={30}
      >
        {tournaments.map((tournament) => (
          <SwiperSlide className="w-5/6 pb-6" key={tournament.id}>
            <Card className="shadow-md">
              <Link to={`/tournaments/${tournament.id}/games`}>
                <CardHeader>
                  <CardTitle>{tournament.name}</CardTitle>
                  <CardDescription>{tournament.date}</CardDescription>
                  <CardDescription>{tournament.location}</CardDescription>
                </CardHeader>
              </Link>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default SwiperUpcomingGames;
