import { Link } from 'react-router-dom';

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from '../ui/navigation-menu.jsx';
import { Separator } from '../ui/separator.jsx';

const navItems = [
  { label: 'Главная', to: '/' },
  { label: 'Игроки', to: '#' },
  { label: 'Турниры', to: '/tournaments/' },
  { label: 'Логин', to: '/login/' },
];

function NavMenu() {
  return (
    <>
      <Separator className="my-4" />
      <div className="space-y-1">
        <NavigationMenu>
          <NavigationMenuList className="space-x-2">
            {navItems.map((item, index) => (
              <NavigationMenuItem className={navigationMenuTriggerStyle()} key={index}>
                <Link to={item.to}>{item.label}</Link>
              </NavigationMenuItem>
            ))}
          </NavigationMenuList>
        </NavigationMenu>
      </div>
      <Separator className="my-4" />
    </>
  );
}

export default NavMenu;
