import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import ButtonBack from '../../components/ButtonBack';
import GameChanger from '../../components/GameChanger';
import GameProgress from '../../components/GameProgress';
import LiveGameInfo from '../../components/LiveGameInfo';
import LiveScorePanel from '../../components/LiveScorePanel';
import Spinner from '../../components/Spinner';
import { Card, CardContent } from '../../components/ui/card';
import { Sheet, SheetTrigger } from '../../components/ui/sheet';
import { clearGameSession, fetchLiveGameSession } from '../../store/gameSessionSlice';
import LiveGame from '../LiveGame';

const LiveGameBox = () => {
  const { gameId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(fetchLiveGameSession(gameId));
      setLoading(false);
    };
    fetchData();

    return () => {
      dispatch(clearGameSession());
    };
  }, [gameId]);

  const gameSessionStatus = useSelector((state) => state.gameSession.status);
  if (gameSessionStatus === 'rejected') {
    return <Navigate to={`/game/${gameId}`} />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="mx-4">
      <div className="flex items-center justify-between">
        <ButtonBack />
        <LiveGameInfo />
      </div>
      <Card>
        <CardContent>
          <Sheet onOpenChange={setIsSheetOpen} open={isSheetOpen}>
            <SheetTrigger asChild>
              <button className="w-full" onClick={() => setIsSheetOpen(true)}>
                <LiveScorePanel />
                <GameProgress />
              </button>
            </SheetTrigger>
            <GameChanger isSheetOpen={isSheetOpen} />
          </Sheet>
          <LiveGame />
        </CardContent>
      </Card>
    </main>
  );
};

export default LiveGameBox;
