import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';

import getResponse from '../../api/getResponseGames';
import ButtonBack from '../../components/ButtonBack';
import GameItem from '../../components/GameItem';
import Header from '../../components/Header';
import Tab from '../../components/Tab';

const fetchGames = async (tournamentsId, statusGame) => {
  let gameList = [];
  if (statusGame === 'finished') {
    gameList = await getResponse(tournamentsId, true, false);
  } else if (statusGame === 'live') {
    gameList = await getResponse(tournamentsId, false, true);
  } else if (statusGame === 'notStarted') {
    gameList = await getResponse(tournamentsId, false, false);
  }
  return gameList;
};

export const gamesLoader = async ({ params, request }) => {
  const url = new URL(request.url);
  const statusGame = url.searchParams.get('status_game') || 'finished';
  return fetchGames(params.tournamentsId, statusGame);
};

const Games = () => {
  useEffect(() => {
    document.title = `Игры | Турнира | My Flag Football`;
  }, []);

  const gamesList = useLoaderData();
  const statusGame = 'status_game';

  const navItems = ['finished', 'live', 'notStarted'];

  return (
    <>
      <Header />
      <main className="mx-4">
        <ButtonBack />
        <h2>Games list:</h2>
        <Tab items={navItems} paramName={statusGame} />
        <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
          {gamesList.map((game) => (
            <GameItem game={game} key={game.id} />
          ))}
        </div>
      </main>
    </>
  );
};
export default Games;
