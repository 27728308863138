import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CardTitle } from '../ui/card';
import { Separator } from '../ui/separator';

const LiveScorePanel = () => {
  const gameSession = useSelector((state) => state.gameSession.gameSession);

  const {
    attackTeamId,
    awayTeamId = gameSession.awayTeam.id,
    awayTeamName = gameSession.awayTeam.name,
    awayTeamScore = gameSession.awayScore,
    homeTeamId = gameSession.homeTeam.id,
    homeTeamName = gameSession.homeTeam.name,
    homeTeamScore = gameSession.homeScore,
  } = gameSession;

  useEffect(() => {
    document.title = `Игра ${homeTeamName} vs. ${awayTeamName} | Турнир | My Flag Football`;
  }, []);

  return (
    <>
      <CardTitle className="mt-6 flex items-center gap-2 text-lg">
        <span>
          {attackTeamId === homeTeamId ? `🏈 ` : ''}
          {homeTeamName}
        </span>
        <span className="flex gap-1">
          <span className="team-score">{homeTeamScore}</span>
          <span>:</span>
          <span className="team-score">{awayTeamScore}</span>
        </span>
        <span>
          {attackTeamId === awayTeamId ? `🏈 ` : ''}
          {awayTeamName}
        </span>
      </CardTitle>
      <Separator className="my-1" />
    </>
  );
};

export default LiveScorePanel;
