import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';

import getResponse from '../../api/getResponseTournaments';
import Header from '../../components/Header';
import Tab from '../../components/Tab';
import TournamentItem from '../../components/TournamentItem';

export const tournamentsLoader = async ({ request }) => {
  const url = new URL(request.url);
  const genderType = url.searchParams.get('gender_type');
  const updatedGenderType = genderType === null ? 'mix' : genderType;
  const tournamentsList = await getResponse(updatedGenderType);
  return tournamentsList;
};

const Tournaments = () => {
  const tournamentsList = useLoaderData();
  const navItems = ['mix', 'woman', 'man'];

  useEffect(() => {
    document.title = `Турниры | My Flag Football`;
  }, []);

  return (
    <>
      <Header />
      <main className="mx-4">
        <h2>Tournaments list:</h2>
        <Tab items={navItems} />
        <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
          {tournamentsList.map(({ dateStart, id, name }) => (
            <TournamentItem dateStart={dateStart} key={id} name={name} tournamentId={id} />
          ))}
        </div>
      </main>
    </>
  );
};

export default Tournaments;
