/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import AxiosApi from '../api/axios-api';
import snakeToCamel from '../services/snakeToCamel';

export const fetchGameSession = createAsyncThunk('gameSession/fetchGameSession', async (gameId) => {
  const liveSession = await AxiosApi.get(`/game/${gameId}/`);
  return snakeToCamel(liveSession.data);
});

export const fetchLiveGameSession = createAsyncThunk(
  'gameSession/fetchLiveGameSession',
  async (gameId) => {
    const liveSession = await AxiosApi.get(`/game-session/${gameId}/`);
    return snakeToCamel(liveSession.data.gameSession);
  },
);

export const updateGameSession = createAsyncThunk(
  'gameSession/updateGameSession',
  async (payload) => {
    const liveSession = await AxiosApi.patch(`/game-session/`, payload);
    return snakeToCamel(liveSession.data.gameSession);
  },
);

export const updateLiveGameSession = createAsyncThunk(
  'gameSession/updateLiveGameSession',
  async (payload) => {
    const liveSession = await AxiosApi.post('/event/', payload);
    return snakeToCamel(liveSession.data.gameSession);
  },
);

export const flagSubmit = createAsyncThunk('gameSession/flagSubmit', async (payload) => {
  const liveSession = await AxiosApi.post('/flag/', payload);
  return snakeToCamel(liveSession.data.gameSession);
});

export const updateEvent = createAsyncThunk('gameSession/updateEvent', async (payload) => {
  const liveSession = await AxiosApi.patch('/event/', payload);
  return snakeToCamel(liveSession.data.gameSession);
});

export const updateInfoPlayer = createAsyncThunk(
  'gameSession/updateInfoPlayer',
  async (payload) => {
    const liveSession = await AxiosApi.patch('/players/', payload);
    return snakeToCamel(liveSession.data.gameSession);
  },
);

export const deletePlayer = createAsyncThunk('gameSession/deletePlayer', async (deleteInfo) => {
  const { idGame, idPlayer } = deleteInfo;
  const liveSession = await AxiosApi.delete(`/players/${idGame}/${idPlayer}/`);
  return snakeToCamel(liveSession.data.gameSession);
});

export const addNewPlayer = createAsyncThunk('gameSession/addNewPlayer', async (newPlayerInfo) => {
  const liveSession = await AxiosApi.post('/players/', newPlayerInfo);
  return snakeToCamel(liveSession.data.gameSession);
});

const initialState = {
  error: null,
  gameSession: null,
  status: null,
};

const gameSessionSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchGameSession.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.gameSession = action.payload;
    });
    builder.addCase(fetchGameSession.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.gameSession = null;
    });
    builder.addCase(fetchLiveGameSession.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.gameSession = action.payload;
    });
    builder.addCase(fetchLiveGameSession.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.gameSession = null;
    });
    builder.addCase(updateGameSession.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.gameSession = action.payload;
    });
    builder.addCase(updateGameSession.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.gameSession = null;
    });
    builder.addCase(updateLiveGameSession.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.gameSession = action.payload;
    });
    builder.addCase(updateLiveGameSession.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.gameSession = null;
    });
    builder.addCase(flagSubmit.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.gameSession = action.payload;
    });
    builder.addCase(flagSubmit.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.gameSession = null;
    });
    builder.addCase(updateEvent.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.gameSession = action.payload;
    });
    builder.addCase(updateEvent.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.gameSession = null;
    });
    builder.addCase(updateInfoPlayer.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.gameSession = action.payload;
    });
    builder.addCase(updateInfoPlayer.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.gameSession = null;
    });
    builder.addCase(deletePlayer.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.gameSession = action.payload;
    });
    builder.addCase(deletePlayer.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.gameSession = null;
    });
    builder.addCase(addNewPlayer.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.gameSession = action.payload;
    });
    builder.addCase(addNewPlayer.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.gameSession = null;
    });
  },
  initialState,
  name: 'gameSession',
  reducers: {
    clearGameSession: (state) => {
      state.gameSession = null;
    },
  },
});

export const { clearGameSession } = gameSessionSlice.actions;

export default gameSessionSlice.reducer;
