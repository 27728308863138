import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../components/ui/button';
import { updateEvent, updateLiveGameSession } from '../../../store/gameSessionSlice';
import AdditionalInfoScreen from '../AdditionalInfoScreen';
import FlagScreen from '../FlagScreen';
import SackScreen from '../SackScreen';
import SelectPlayers from '../SelectPlayers';

const GameEvents = ({ disabled, selectedTeamId }) => {
  const dispatch = useDispatch();
  const { eventId, flowInfo, gameId } = useSelector((state) => state.gameSession.gameSession);
  const [currentScreen, setCurrentScreen] = useState(false);
  const [activeTeamValue, setActiveTeamValue] = useState('');
  const [eventTypeValue, setEventTypeValue] = useState('');
  const [availableScreensValue, setAvailableScreensValue] = useState({});
  const [externalPayload, setExternalPayload] = useState({});

  const availableScreens = {
    additionalInfo: 'additionalInfo',
    flag: 'flag',
    playerSelection: 'playerSelection',
    sack: 'sack',
  };

  const handlePlayerSelect = (playerId) => {
    externalPayload.playerId = Number(playerId);
    if (eventId === null) {
      dispatch(updateLiveGameSession(externalPayload));
    } else {
      dispatch(updateEvent(externalPayload));
    }
    setCurrentScreen(false);
  };

  const handleFlagSelect = () => {
    setCurrentScreen(false);
  };

  const handleSackSelect = () => {
    setCurrentScreen(false);
  };

  const handleAdditionalInfoSelect = () => {
    setCurrentScreen(false);
  };

  const buttonFunction = (item) => {
    const { additionalInfo, eventType, needPlayerIdFromTeam } = item;
    const payload = { eventType, gameId };

    switch (eventType) {
      case 'confirm_roster':
      case 'time_starts':
        dispatch(updateLiveGameSession(payload));
        break;
      case 'set_attacking_team':
        payload.teamId = Number(selectedTeamId);
        dispatch(updateLiveGameSession(payload));
        break;
      case 'flag':
        setCurrentScreen(availableScreens.flag);
        break;
      default:
        if (additionalInfo !== null) {
          setEventTypeValue(eventType);
          setAvailableScreensValue(item);
          setCurrentScreen(availableScreens.additionalInfo);
        } else if (needPlayerIdFromTeam === null && eventId === null) {
          dispatch(updateLiveGameSession(payload));
        } else if (needPlayerIdFromTeam === null && eventId !== null) {
          payload.eventId = Number(eventId);
          dispatch(updateEvent(payload));
        } else if (needPlayerIdFromTeam === 'both') {
          setCurrentScreen(availableScreens.sack);
        } else {
          payload.eventId = Number(eventId);
          setActiveTeamValue(needPlayerIdFromTeam);
          setEventTypeValue(eventType);
          setExternalPayload(payload);
          setCurrentScreen(availableScreens.playerSelection);
        }
        break;
    }
  };

  return (
    <>
      {!currentScreen && (
        <div className="flex flex-wrap gap-4">
          {flowInfo.map((item) => (
            <Button
              className="w-full"
              disabled={disabled}
              key={item.eventType}
              onClick={() => buttonFunction(item)}
            >
              {item.label}
            </Button>
          ))}
        </div>
      )}
      {currentScreen === availableScreens.playerSelection && (
        <SelectPlayers
          activeTeamValue={activeTeamValue}
          eventTypeValue={eventTypeValue}
          onPlayerSelect={handlePlayerSelect}
        />
      )}
      {currentScreen === availableScreens.flag && <FlagScreen onFlagSelect={handleFlagSelect} />}
      {currentScreen === availableScreens.sack && <SackScreen onSackSelect={handleSackSelect} />}
      {currentScreen === availableScreens.additionalInfo && (
        <AdditionalInfoScreen
          availableScreensValue={availableScreensValue}
          eventTypeValue={eventTypeValue}
          onAdditionalInfoSelect={handleAdditionalInfoSelect}
        />
      )}
    </>
  );
};

export default GameEvents;
