import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateLiveGameSession } from '../../../store/gameSessionSlice';

const SackScreen = ({ onSackSelect }) => {
  const dispatch = useDispatch();
  const gameSession = useSelector((state) => state.gameSession.gameSession);
  const { attackTeamId, awayTeam, gameId, homeTeam } = gameSession;
  const attackTeam = attackTeamId === homeTeam.id ? homeTeam : awayTeam;
  const defenseTeam = attackTeamId !== homeTeam.id ? homeTeam : awayTeam;

  const [selectedQbId, setSelectedQbId] = useState(null);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);

  const handleQbSelect = (sackedQbId) => {
    setSelectedQbId(sackedQbId);
  };

  const handlePlayerSelect = (playerId) => {
    setSelectedPlayerId(playerId);
  };

  const handleSubmit = () => {
    const payload = {
      eventType: 'sack',
      gameId,
      playerId: selectedPlayerId,
      sackedQbId: selectedQbId,
    };

    dispatch(updateLiveGameSession(payload));
    onSackSelect();
  };

  useEffect(() => {
    if (selectedQbId && selectedPlayerId) {
      handleSubmit();
    }
  }, [selectedQbId, selectedPlayerId]);

  return (
    <>
      {selectedQbId === null && (
        <>
          <h3>Выберете игрока с мячом команды {attackTeam.name} которого секнули</h3>
          <div className="players__list">
            {attackTeam.players.map((player) => (
              <button
                className={
                  attackTeam.lastQbId === player.id
                    ? 'players__item button qb'
                    : 'players__item button'
                }
                key={player.id}
                onClick={() => handleQbSelect(player.id)}
              >
                <span className="players__number">{player.jerseyNumber}</span>
                <span className="players__text">
                  {player.firstName} {player.lastName.slice(0, 1).toUpperCase()}
                </span>
              </button>
            ))}
          </div>
        </>
      )}
      {selectedQbId !== null && selectedPlayerId === null && (
        <>
          <h3>Выберете игрока команды {defenseTeam.name} который секнул</h3>
          <div className="players__list">
            {defenseTeam.players.map((player) => (
              <button
                className="players__item button"
                key={player.id}
                onClick={() => handlePlayerSelect(player.id)}
              >
                <span className="players__number">{player.jerseyNumber}</span>
                <span className="players__text">
                  {player.firstName} {player.lastName.slice(0, 1).toUpperCase()}
                </span>
              </button>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SackScreen;
