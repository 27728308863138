import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './global.css';
import ErrorPage from './pages/Error';
import GameBox from './pages/GameBox';
import Games, { gamesLoader } from './pages/Games';
import Home from './pages/Home';
import LiveGameBox from './pages/LiveGameBox';
import Login, { loginAction } from './pages/Login';
import Tournaments, { tournamentsLoader } from './pages/Tournaments';
import store from './store';

const router = createBrowserRouter([
  {
    element: <Home />,
    errorElement: <ErrorPage />,
    path: '/',
  },
  {
    action: loginAction,
    element: <Login />,
    errorElement: <ErrorPage />,
    path: 'login',
  },
  {
    element: <Tournaments />,
    errorElement: <ErrorPage />,
    loader: tournamentsLoader,
    path: 'tournaments',
  },
  {
    element: <Games />,
    errorElement: <ErrorPage />,
    loader: gamesLoader,
    path: 'tournaments/:tournamentsId/games',
  },
  {
    element: <LiveGameBox />,
    errorElement: <ErrorPage />,
    path: 'live-game/:gameId',
  },
  {
    element: <GameBox />,
    errorElement: <ErrorPage />,
    path: 'game/:gameId',
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
);
