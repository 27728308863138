import NavMenu from '../NavMenu';

const Header = () => (
  <header className="mx-4">
    <div className="my-4 flex justify-center">
      <img alt="logo" height="auto" src="/images/svg/logo_black.svg" width="200" />
    </div>
    <NavMenu />
  </header>
);

export default Header;
