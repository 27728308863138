import { Link } from 'react-router-dom';

import { Card, CardContent, CardDescription, CardTitle } from '../ui/card';

const GameItem = ({ game }) => {
  const { awayScore, awayTeam, finished, homeScore, homeTeam, id, inProgress } = game;

  // eslint-disable-next-line no-nested-ternary
  const winner = awayScore > homeScore ? 'away' : homeScore > awayScore ? 'home' : null;

  return (
    <Card className="w-full shadow-md">
      <Link to={`${finished ? '/game/' : '/live-game/'}${id}`}>
        <CardContent className="flex gap-4 px-4 py-2">
          <div className="flex grow flex-col gap-2">
            <CardTitle className="text-lg">{homeTeam.name}</CardTitle>
            <CardTitle className="text-lg">{awayTeam.name}</CardTitle>
          </div>
          {!finished && inProgress && (
            <div className="flex flex-col justify-center gap-2">
              <div className="flex items-center">
                <div className="blob"></div>
                <span>live</span>
              </div>
            </div>
          )}
          {(finished || inProgress) && (
            <div className="flex flex-col justify-center gap-2">
              <CardDescription
                className={`w-[2ch] text-right text-lg ${finished && winner === 'home' ? 'font-bold' : ''}`}
              >
                {homeScore}
              </CardDescription>

              <CardDescription
                className={`w-[2ch] text-right text-lg ${finished && winner === 'away' ? 'font-bold' : ''}`}
              >
                {awayScore}
              </CardDescription>
            </div>
          )}
          {!finished && !inProgress && (
            <span className="flex items-center">
              <svg height="1em" viewBox="0 0 24 24" width="1em" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth={2}>
                  <circle cx={12} cy={12} r={1}></circle>
                  <circle cx={6} cy={12} r={1}></circle>
                  <circle cx={18} cy={12} r={1}></circle>
                </g>
              </svg>
            </span>
          )}
        </CardContent>
      </Link>
    </Card>
  );
};

export default GameItem;
