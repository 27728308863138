import { Undo2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../ui/button';

const ButtonBack = () => {
  const navigate = useNavigate();

  return (
    <Button className="my-4" onClick={() => navigate(-1)}>
      <Undo2 />
    </Button>
  );
};

export default ButtonBack;
